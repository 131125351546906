import _ from 'lodash'

export const generateColors = outputLength => {
    const colors = [
        { r: 50, g: 50, b: 255 },
        { r: 50, g: 255, b: 50 },
        { r: 255, g: 50, b: 50 }
    ]
    const rDifference1 = colors[1].r - colors[0].r
    const gDifference1 = colors[1].g - colors[0].g
    const bDifference1 = colors[1].b - colors[0].b

    const rDifference2 = colors[2].r - colors[1].r
    const gDifference2 = colors[2].g - colors[1].g
    const bDifference2 = colors[2].b - colors[1].b

    const rStep1 = (rDifference1 / (outputLength - 1)) * 2
    const gStep1 = (gDifference1 / (outputLength - 1)) * 2
    const bStep1 = (bDifference1 / (outputLength - 1)) * 2

    const rStep2 = (rDifference2 / (outputLength - 1)) * 2
    const gStep2 = (gDifference2 / (outputLength - 1)) * 2
    const bStep2 = (bDifference2 / (outputLength - 1)) * 2

    const output = _.times(outputLength, i => {
        if (i === 0)
            return `rgb(${colors[0].r}, ${colors[0].g}, ${colors[0].b})`
        if (i === outputLength - 1)
            return `rgb(${colors[2].r}, ${colors[2].g}, ${colors[2].b})`
        if (i < outputLength / 2) {
            return `rgb(${Math.round(colors[0].r + rStep1 * i)}, ${Math.round(
                colors[0].g + gStep1 * i
            )}, ${Math.round(colors[0].b + bStep1 * i)})`
        }
        return `rgb(${Math.round(
            colors[1].r + rStep2 * (i - outputLength / 2)
        )}, ${Math.round(
            colors[1].g + gStep2 * (i - outputLength / 2)
        )}, ${Math.round(colors[1].b + bStep2 * (i - outputLength / 2))})`
    })
    return output
}
