/**
 * Configure redux store
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { PERSIST_KEY } from 'constants'

import axios from 'axios'
import { routerMiddleware } from 'connected-react-router'
import { history, rootReducer } from 'reducers'
import { applyMiddleware, compose, createStore } from 'redux'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

// Create client aliases
// Used in action creators
const clients = {
    default: {
        client: axios.create({
            baseURL:
                process.env.OIDC_API ||
                (process.env.NODE_ENV === 'production'
                    ? 'https://oidc-client-dot-tvv-api.appspot.com'
                    : 'http://localhost:3000'),
            responseType: 'json'
        })
    },
    analytics: {
        client: axios.create({
            baseURL:
                process.env.ANALYTICS_API ||
                (process.env.NODE_ENV === 'production'
                    ? 'https://data.waltti.fi/proxyservice/admin'
                    : 'http://localhost:3000/proxyservice/admin'),
            responseType: 'json'
        })
    }
}

// Config redux-persist
const persistConfig = {
    key: PERSIST_KEY,
    storage,
    blacklist: [
        'form',
        'router',
        'error',
        'login', // Required to overwrite the blacklisted content inside login reducer.
        'apiKey'
    ]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Define middleware to use
const tools = [
    applyMiddleware(
        thunk,
        multiClientMiddleware(clients),
        routerMiddleware(history)
    )
]
if (window.__REDUX_DEVTOOLS_EXTENSION__)
    tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())

// Create redux store
const store = createStore(persistedReducer, compose(...tools))
let persistor = persistStore(store)

export { store, persistor }
