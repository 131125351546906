/**
 * A Reusable wrapper component
 * Define authentication state and pass it to its children
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isAuthenticated, showAnalytics } from 'reducers'

class AuthCheck extends Component {
    render() {
        return this.props.children({
            isAuthenticated: this.props.authenticated,
            showAnalytics: this.props.showAnalytics
        })
    }
}

const mapStateToProps = state => ({
    authenticated: isAuthenticated(state),
    showAnalytics: showAnalytics(state)
})

export default connect(mapStateToProps)(AuthCheck)
