/**
 * @author Antti Vuorenmaa <antti@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import moment from 'moment'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'

import LineChart from './Analytics/LineChart'

export default class AnalyticsPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            charts: [
                {
                    type: 'basic-line',
                    title: 'Number of requests / instance',
                    infoText: 'Number of request made to the API',
                    tabSelection: [
                        {
                            text: 'Hourly',
                            value: 'hour',
                            selected: false,
                            index: 0
                        },
                        {
                            text: 'Daily',
                            value: 'day',
                            selected: true,
                            index: 1
                        },
                        {
                            text: 'Weekly',
                            value: 'week',
                            selected: false,
                            index: 2
                        },
                        {
                            text: 'Monthly',
                            value: 'month',
                            selected: false,
                            index: 3
                        }
                    ],
                    filters: [{ type: 'date' }],
                    accuracy: 'day',
                    tabOnSelect: tab => this.selectTab(0, tab),
                    returnParams: () =>
                        this.getReturnParams(0, {
                            accuracy: true,
                            dateFromNow: {
                                hour: 7 * 24,
                                day: 7,
                                week: 12,
                                month: 12
                            }
                        }),
                    index: 0
                },
                {
                    type: 'basic-line',
                    title: 'Number of successful and failed requests',
                    infoText:
                        'Number of successful and failed requests from all instances',
                    tabSelection: [
                        {
                            text: 'Hourly',
                            value: 'hour',
                            selected: false,
                            index: 0
                        },
                        {
                            text: 'Daily',
                            value: 'day',
                            selected: true,
                            index: 1
                        },
                        {
                            text: 'Weekly',
                            value: 'week',
                            selected: false,
                            index: 2
                        },
                        {
                            text: 'Monthly',
                            value: 'month',
                            selected: false,
                            index: 3
                        }
                    ],
                    filters: [{ type: 'date' }],
                    accuracy: 'day',
                    tabOnSelect: tab => this.selectTab(1, tab),
                    returnParams: () =>
                        this.getReturnParams(1, {
                            accuracy: true,
                            dateFromNow: {
                                hour: 7 * 24,
                                day: 7,
                                week: 12,
                                month: 12
                            },
                            selection: ['hit', 'miss']
                        }),
                    index: 1
                },
                {
                    type: 'basic-line',
                    title: 'Number of requests / user',
                    infoText: 'Number of requests made by single user',
                    tabSelection: [
                        {
                            text: 'Hourly',
                            value: 'hour',
                            selected: false,
                            index: 0
                        },
                        {
                            text: 'Daily',
                            value: 'day',
                            selected: true,
                            index: 1
                        },
                        {
                            text: 'Weekly',
                            value: 'week',
                            selected: false,
                            index: 2
                        },
                        {
                            text: 'Monthly',
                            value: 'month',
                            selected: false,
                            index: 3
                        }
                    ],
                    filters: [{ type: 'date' }],
                    accuracy: 'day',
                    tabOnSelect: tab => this.selectTab(2, tab),
                    returnParams: () =>
                        this.getReturnParams(2, {
                            accuracy: true,
                            dateFromNow: {
                                hour: 7 * 24,
                                day: 7,
                                week: 12,
                                month: 12
                            },
                            selection: ['key']
                        }),
                    index: 2
                }
            ]
        }
    }

    selectTab = (index, tab) => {
        const charts = _.cloneDeep(this.state.charts)
        charts[index].tabSelection = charts[index].tabSelection.map(value => {
            if (value.index === tab.index) return { ...value, selected: true }
            return { ...value, selected: false }
        })
        charts[index].accuracy = tab.value

        return this.setState({ charts })
    }

    getReturnParams = (index, options) => {
        const params = {}
        const { accuracy } = this.state.charts.find(
            chart => chart.index === index
        )
        if (options.accuracy) {
            params.accuracy = accuracy
        }
        if (options.dateFromNow) {
            params.startDate = moment()
                .subtract(options.dateFromNow[accuracy], accuracy)
                .format('YYYY-MM-DDTHH:mm:ssZ')
            params.endDate = moment().format('YYYY-MM-DDTHH:mm:ssZ')
        }
        if (options.selection) {
            params.selection = options.selection
        } else {
            params.selection = 'city'
        }
        return params
    }

    render() {
        const { charts } = this.state

        return (
            <div className="app-page-container">
                <Helmet>
                    <title>Analytics</title>
                    <meta name="description" content="TVV-API analytics" />
                </Helmet>
                <div className="analytics-page">
                    <div className="docs-page">
                        <div className="app-centered-content">
                            <div className="app-content-width-controller">
                                <h1>API analytics</h1>

                                {charts.map(chart => {
                                    if (chart.type === 'basic-line')
                                        return (
                                            <LineChart
                                                {...chart}
                                                key={`analytics_chart_${chart.index}`}
                                            />
                                        )
                                    return null
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
