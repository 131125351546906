/**
 * OAuth2 component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import * as qs from 'query-string'
import { Redirect } from 'react-router-dom'
import { login } from '../actions/login'
import { getAuthState } from 'reducers'
import LoadingCircle from './LoadingCircle'

class OAuth2 extends Component {
    state = {
        valid: true,
        reason: undefined,
        redirect: undefined
    }

    componentDidMount() {
        const params = qs.parse(location.search)
        const code = params.code
        if (params.error) {
            this.setState({
                valid: false,
                reason: params.error_description
            })
            setTimeout(() => this.setState({ redirect: '/' }), 3000)
            return
        }
        if (!code) {
            this.setState({
                valid: false,
                redirect: '/'
            })
            return
        }
        this.props.login(code)
    }

    render() {
        const { authState } = this.props

        if (!this.state.reason) {
            if (!this.state.valid) return <Redirect to="/" />
            if (authState === false) return <Redirect to="/login" />
            if (authState === true) return <Redirect to="/credentials" />
        }
        if (this.state.redirect) return <Redirect to={this.state.redirect} />
        return (
            <div className="oauth2-component-container">
                {!this.state.reason ? (
                    <Fragment>
                        <LoadingCircle size={48} color="black" />
                        <div className="loading-text">Authenticating...</div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="error-text">
                            Error: {this.state.reason}
                        </div>
                        <div className="redirect-text">
                            You will be redirected in 3 seconds, or click{' '}
                            <a href="/">here</a>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        authState: getAuthState(state)
    }),
    (dispatch, ownProps) => ({
        login: code => dispatch(login({ code }))
    })
)(OAuth2)
