/**
 * FeatureTable component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell, Table, TableHead, TableBody } from './Table'

export class FeedElement {
    constructor(
        field,
        type = undefined,
        supported = undefined,
        comments = undefined,
        children = undefined
    ) {
        this.field = field
        this.type = type
        this.supported = supported
        this.comments = comments
        this.children = children
    }
}

export default class FeatureTable extends Component {
    static propTypes = {
        features: PropTypes.array
    }

    renderField = (feat, indentation) => {
        return (
            <div style={{ paddingLeft: `${indentation * 24}px` }}>
                {indentation === 0 ? <b>{feat.field}</b> : feat.field}
                {feat.type ? ` (${feat.type})` : ''}
            </div>
        )
    }

    renderFeature = feat => this.renderRecursiveFeature(feat, feat.field)

    renderRecursiveFeature = (feat, path, indentation = 0) => {
        return (
            <Fragment key={path}>
                <TableRow>
                    <TableCell>{this.renderField(feat, indentation)}</TableCell>
                    <TableCell>{feat.supported}</TableCell>
                    <TableCell>
                        {(feat.comments || '').split('\n').map((v, i) => (
                            <div key={i}>{v}</div>
                        ))}
                    </TableCell>
                </TableRow>
                {Boolean(feat.children) &&
                    feat.children.map(v =>
                        this.renderRecursiveFeature(
                            v,
                            `${name}.${v.field}`,
                            indentation + 1
                        )
                    )}
            </Fragment>
        )
    }

    render() {
        const { features } = this.props
        return (
            <div className="feature-table-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Supported</TableCell>
                            <TableCell>Comments</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{features.map(this.renderFeature)}</TableBody>
                </Table>
            </div>
        )
    }
}
