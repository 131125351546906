/**
 * Code component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'

export default class Code extends Component {
    render() {
        const { children } = this.props
        return (
            <div className="code-component-container">
                <code>{children}</code>
            </div>
        )
    }
}
