/**
 * ApiKey reducer
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import {
    GET_APIKEYS,
    GET_APIKEYS_SUCCESS,
    GET_APIKEYS_FAIL,
    CREATE_APIKEY,
    CREATE_APIKEY_SUCCESS,
    CREATE_APIKEY_FAIL,
    EDIT_APIKEY,
    EDIT_APIKEY_SUCCESS,
    EDIT_APIKEY_FAIL,
    DELETE_APIKEY,
    DELETE_APIKEY_SUCCESS,
    DELETE_APIKEY_FAIL
} from 'actions/types'

const initialState = {
    loading: undefined,
    keys: []
}

export default function apiKey(state = initialState, action) {
    switch (action.type) {
        case GET_APIKEYS:
        case CREATE_APIKEY:
        case EDIT_APIKEY:
        case DELETE_APIKEY:
            return {
                ...state,
                loading: true
            }
        case GET_APIKEYS_SUCCESS:
        case CREATE_APIKEY_SUCCESS:
        case EDIT_APIKEY_SUCCESS:
        case DELETE_APIKEY_SUCCESS:
            return {
                ...state,
                loading: false,
                keys: action.payload.data
            }
        case GET_APIKEYS_FAIL:
        case CREATE_APIKEY_FAIL:
        case EDIT_APIKEY_FAIL:
        case DELETE_APIKEY_FAIL:
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}

export const getKeys = state => (state ? state.apiKey.keys : initialState.keys)
