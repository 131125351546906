/**
 * @author Antti Vuorenmaa <antti@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { GET_ANALYTICS_DATA } from './types'

export const getAnalyticsData = params => (dispatch, getState) => {
    const { bearer } = getState().login

    const request = {
        type: GET_ANALYTICS_DATA,
        payload: {
            client: 'analytics',
            request: {
                method: 'GET',
                url: `/analytics?${params}`,
                headers: {
                    bearer
                }
            }
        }
    }

    return dispatch(request)
}
