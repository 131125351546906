/**
 * Credentials page component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
// import { getKeys } from 'reducers'
// import { getAPIKeys } from 'actions'
// import { createAPIKey, editAPIKey, deleteAPIKey } from '../actions'
// import CreateKeyForm from './Form/CreateKeyForm'
// import EditKeyForm from './Form/EditKeyForm'

class CredentialsPage extends Component {
    // handleCreateKeySubmit = values => {
    //     this.props.createKey(values)
    // }

    // handleEditKeySubmit = (values, dispatch, props) => {
    //     if (values.comment !== props.item.comment)
    //         // Don't send anything if nothing has changed
    //         this.props.editKey(values, props.item.key)
    // }

    // handleDeleteKey = key => {
    //     this.props.deleteKey(key)
    // }

    // renderKey = (item, index) => {
    //     return (
    //         <EditKeyForm
    //             key={item.key}
    //             form={`editKeyForm-${item.key}`}
    //             item={item}
    //             index={index}
    //             initialValues={{
    //                 comment: item.comment
    //             }}
    //             onSubmit={this.handleEditKeySubmit}
    //             onDelete={this.handleDeleteKey}
    //         />
    //     )
    // }

    // componentDidMount() {
    //     this.props.getKeys()
    // }

    render() {
        // const { keys, loading } = this.props
        return (
            <div className="credentials-page-container">
                <Helmet>
                    <title>Credentials</title>
                    <meta
                        name="description"
                        content={`${
                            process.env.PLATFORM === 'nysse'
                                ? 'Nysse'
                                : 'Waltti'
                        } credentials page`}
                    />
                </Helmet>
                {/* <h2 className="credentials-title-container">My active keys</h2>
                <div className="credentials-list-container">
                    {keys.map(this.renderKey)}
                </div>
                <h2 className="credentials-title-container">Request new key</h2>
                <CreateKeyForm onSubmit={this.handleCreateKeySubmit} /> */}
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        // keys: getKeys(state),
        // loading: state.apiKey.loading
    }),
    (dispatch, ownProps) => ({
        // getKeys: () => dispatch(getAPIKeys()),
        // createKey: values => dispatch(createAPIKey(values)),
        // editKey: (values, key) => dispatch(editAPIKey(values, key)),
        // deleteKey: key => dispatch(deleteAPIKey(key))
    })
)(CredentialsPage)
