import React from 'react'
import PropTypes from 'prop-types'

const TabSelection = props => (
    <div className={'analytics-tabSelection'}>
        {props.values.map(value => (
            <div
                className={`button ${value.selected ? 'selected' : ''}`}
                onClick={() => props.handleSelect(value)}
                key={`analytics_tab_item_${value.text}`}
            >
                {value.text}
            </div>
        ))}
    </div>
)

TabSelection.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            selected: PropTypes.bool.isRequired
        })
    ).isRequired,
    handleSelect: PropTypes.func.isRequired
}

export default TabSelection
