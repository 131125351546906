/**
 * Root reducer
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { combineReducers } from 'redux'
import { reducer as reduxFormReducer } from 'redux-form'

import { CLEAR_ERROR_MESSAGES } from '../actions/types'
// API key reducer
import apiKey, { getKeys } from './apiKey'
// login reducer
import login, {
    getAuthState,
    getBearer,
    getUrl,
    getUser,
    getVerifying,
    isAuthenticated,
    showAnalytics
} from './login'

const history = createBrowserHistory()

// error handling reducer
// return error message if there is error returned from action
const error = (state = null, action) => {
    const { type, error } = action
    // Because there might be different forms of error
    // returned from the server
    // Just return error and handle UI res (eg. message) inside component
    // If we came up with a "global" error return
    // then return error message here
    if (error && error.response && error.response.data) {
        // console.log(error.response.data)
        return error.response.data
    }
    if (error) {
        // Check for third-party errors
        if (/^@@/.test(action.type)) {
            if (typeof error === 'string') {
                return { message: error }
            } else {
                return { message: `Error: ${action.type}` }
            }
        }
        if (typeof error === 'string') {
            return { message: error }
        } else {
            return { message: 'Oops! Something went wrong' }
        }
    }
    if (type === CLEAR_ERROR_MESSAGES) {
        return null
    }
    return state
}

// export root reducer
export const rootReducer = combineReducers({
    form: reduxFormReducer,
    router: connectRouter(history),
    error,
    login,
    apiKey
})

// export history
export { history }

// router selectors
const getPathName = state => state.router.location.pathname

// export getters/ selectors
// these will be used in individual react component
export {
    // router custom selector
    getPathName,
    // login selectors
    getUser,
    getBearer,
    isAuthenticated,
    showAnalytics,
    getUrl,
    getAuthState,
    getVerifying,
    // apiKey selectors
    getKeys
}
