import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { defaultStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const CodeXML = ({ children }) => {
    return (
        <SyntaxHighlighter language={'javascript'} style={defaultStyle}>
            {children}
        </SyntaxHighlighter>
    )
}

export default CodeXML
