import React, { Fragment } from 'react'

const TableOfContents = ({ data }) => (
    <div className={'table-of-contents-container'}>
        <div className={'table-of-contents'}>
            <h2>Contents</h2>
            {data.map((val, i) => row(val, i + 1, 0))}
        </div>
    </div>
)

const row = (val, index, level) => (
    <Fragment key={`table_of_contents_${index}`}>
        <a style={{ marginLeft: level * 20 }} href={val.value}>
            {index}. {val.text}
        </a>
        {val.children &&
            val.children.map((obj, i) =>
                row(obj, `${index}.${i + 1}`, level + 1)
            )}
    </Fragment>
)

export default TableOfContents
