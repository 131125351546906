/**
 * Contains all table components
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'

export const Table = ({ children, ...rest }) => (
    <div className="table-component-container">
        <div className="tb-comp-table" {...rest}>
            {children}
        </div>
    </div>
)

export const TableHead = ({ children, ...rest }) => (
    <div className="tb-comp-head" {...rest}>
        {children}
    </div>
)

export const TableBody = ({ children, ...rest }) => (
    <div className="tb-comp-body" {...rest}>
        {children}
    </div>
)

export const TableRow = ({ children, ...rest }) => (
    <div className="tb-comp-row" {...rest}>
        {children}
    </div>
)

export const TableCell = ({ children, ...rest }) => {
    return (
        <div className="tb-comp-cell" {...rest}>
            {children}
        </div>
    )
}
