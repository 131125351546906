/**
 * Declare all types and export
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Authentication
export const GET_LOGIN_URL = 'GET_LOGIN_URL'
export const GET_LOGIN_URL_SUCCESS = 'GET_LOGIN_URL_SUCCESS'
export const GET_LOGIN_URL_FAIL = 'GET_LOGIN_URL_FAIL'

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const CLEAR_ERROR_MESSAGES = 'CLEAR_ERROR_MESSAGES'

export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const LOG_OUT_FAIL = 'LOG_OUT_FAIL'

export const VERIFY_TOKEN = 'VERIFY_TOKEN'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAIL = 'VERIFY_TOKEN_FAIL'

// API keys
export const GET_APIKEYS = 'GET_APIKEYS'
export const GET_APIKEYS_SUCCESS = 'GET_APIKEYS_SUCCESS'
export const GET_APIKEYS_FAIL = 'GET_APIKEYS_FAIL'

export const CREATE_APIKEY = 'CREATE_APIKEYS'
export const CREATE_APIKEY_SUCCESS = 'CREATE_APIKEYS_SUCCESS'
export const CREATE_APIKEY_FAIL = 'CREATE_APIKEYS_FAIL'

export const EDIT_APIKEY = 'EDIT_APIKEY'
export const EDIT_APIKEY_SUCCESS = 'EDIT_APIKEY_SUCCESS'
export const EDIT_APIKEY_FAIL = 'EDIT_APIKEY_FAIL'

export const DELETE_APIKEY = 'DELETE_APIKEY'
export const DELETE_APIKEY_SUCCESS = 'DELETE_APIKEY_SUCCESS'
export const DELETE_APIKEY_FAIL = 'DELETE_APIKEY_FAIL'

// Analytics
export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA'
export const GET_ANALYTICS_DATA_SUCCESS = 'GET_ANALYTICS_DATA_SUCCESS'
export const GET_ANALYTICS_DATA_FAIL = 'GET_ANALYTICS_DATA_FAIL'
