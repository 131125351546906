/**
 * GTFSRealtimeDoc component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'

import CodeXML from './CodeXML'
import CustomTable from './CustomTable'
import FeatureTable, { FeedElement } from './FeatureTable'

const cityParam = process.env.PLATFORM === 'nysse' ? 'tampere' : ':city'

export default class GTFSRealtimeDoc extends Component {
    render() {
        return (
            <div className="gtfs-rt-doc-container">
                <h1 id={'gtfs'}>GTFS Realtime</h1>
                <Introduction />
                <TripUpdate />
                <VehiclePosition />
                <Alert />
                {process.env.PLATFORM === 'nysse' && (
                    <>
                        <h1 id={'siri'}>SIRI</h1>
                        <SiriIntroduction />
                        <SiriVehicleMonitoring />
                        <SiriStopMonitoring />
                        <SiriGeneralMessage />
                    </>
                )}
                <h1 id={'gtfs-static'}>GTFS Static</h1>
                <Packages />
            </div>
        )
    }
}

const Introduction = () => (
    <div>
        <h2 id={'gtfs-introduction'}>Introduction</h2>
        <p>
            This document describes what data is available and can be expected
            in the GTFS Realtime feeds.
        </p>
        <p>
            More information about the interface can be found from{' '}
            <a href="https://developers.google.com/transit/gtfs-realtime/">
                https://developers.google.com/transit/gtfs-realtime/
            </a>
        </p>
        <p>
            The provided feed is in <b>binary</b> format providing{' '}
            <b>full dataset</b>. The GTFS Realtime specification version{' '}
            <b>2.0</b> is supported.
        </p>
        <p>Maximum rates for the REST API (per server or device) are:</p>
        <ul>
            <li>Trip Updates every 30 seconds</li>
            <li>Service Alerts every 60 seconds</li>
            <li>Vehicle Positions every 1 seconds</li>
        </ul>

        {process.env.PLATFORM === 'waltti' && (
            <>
                <h2 id="supportedCities">Supported cities</h2>
                <p>Currently the API supports following cities:</p>
                <CityTable />
                <p>
                    In order to use the API, replace{' '}
                    <code className="doc-code">/:city/</code> with the desired
                    city's value <br />
                    (for example{' '}
                    <code className="doc-code">
                        /jyvaskyla/api/gtfsrealtime/v1.0/feed/tripupdate
                    </code>
                    ).
                </p>
            </>
        )}
    </div>
)

const TripUpdate = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'gtfs-tripupdate'}>GTFS Realtime - TripUpdate</h2>
        <code className="doc-endpoint">
            GET /{cityParam}/api/gtfsrealtime/v1.0/feed/tripupdate
        </code>
        <p>
            Available for currently running trips. Cancelled trips exist in the
            feed until the trip is scheduled to arrive to the last stop.
        </p>
        <FeatureTable features={tripFeatures} />
    </div>
)

const VehiclePosition = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'gtfs-vehicleposition'}>GTFS Realtime - VehiclePosition</h2>
        <code className="doc-endpoint">
            GET /{cityParam}/api/gtfsrealtime/v1.0/feed/vehicleposition
        </code>
        <FeatureTable features={positionFeatures} />
    </div>
)

const Alert = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'gtfs-alert'}>GTFS Realtime - Alert</h2>
        <code className="doc-endpoint">
            GET /{cityParam}/api/gtfsrealtime/v1.0/feed/servicealert
        </code>
        <FeatureTable features={alertFeatures} />
    </div>
)

const supportedCities = [
    { name: 'Tampere', value: 'tampere' },
    { name: 'Lahti', value: 'lahti' },
    { name: 'Joensuu', value: 'joensuu' },
    { name: 'Jyväskylä', value: 'jyvaskyla' }
]

const CityTable = () => (
    <table className="doc-cityTable">
        <thead>
            <tr>
                <th>City</th>
                <th>Value</th>
            </tr>
        </thead>
        <tbody>
            {supportedCities.map(city => (
                <tr key={`city_table_${city.value}`}>
                    <td>{city.name}</td>
                    <td>{city.value}</td>
                </tr>
            ))}
        </tbody>
    </table>
)

const tripFeatures = [
    new FeedElement('trip', 'TripDescriptor', undefined, undefined, [
        new FeedElement('trip_id', undefined, 'Yes'),
        new FeedElement('route_id', undefined, 'Yes'),
        new FeedElement(
            'direction_id',
            undefined,
            'Yes',
            'If available in the static feed.'
        ),
        new FeedElement('start_time', undefined, 'Yes'),
        new FeedElement('start_date', undefined, 'Yes'),
        new FeedElement(
            'schedule_relationship',
            'ScheduleRelationship',
            'Yes',
            'Supported values are:\nSCHEDULED\nCANCELED'
        )
    ]),
    new FeedElement('vehicle', 'VehicleDescriptor', 'Yes', undefined, [
        new FeedElement('id', undefined, 'Yes'),
        new FeedElement(
            'label',
            undefined,
            'Yes',
            'trip_headsign if available in the static feed.'
        ),
        new FeedElement(
            'license_plate',
            undefined,
            'Yes',
            'Availability not guaranteed.'
        )
    ]),
    new FeedElement('stop_time_update', 'StopTimeUpdate', 'Yes', undefined, [
        new FeedElement('stop_sequence', undefined, 'No'),
        new FeedElement('stop_id', undefined, 'Yes'),
        new FeedElement('arrival', 'StopTimeEvent', 'Yes', undefined, [
            new FeedElement('delay', undefined, 'No'),
            new FeedElement('time', undefined, 'Yes'),
            new FeedElement('uncertainty', undefined, 'No')
        ]),
        new FeedElement('departure', 'StopTimeEvent', 'Yes', undefined, [
            new FeedElement('delay', undefined, 'No'),
            new FeedElement('time', undefined, 'Yes'),
            new FeedElement('uncertainty', undefined, 'No')
        ]),
        new FeedElement(
            'schedule_relationship',
            'ScheduleRelationship',
            'No',
            'Supported values are:\nSCHEDULED\nNO_DATA if stop is closed\nSKIPPED if partial trip cancellation\nNO_DATA and SKIPPED stop visits may appear only in currently running trips'
        )
    ]),
    new FeedElement('timestamp', undefined, 'Yes'),
    new FeedElement('delay', undefined, 'No')
]

const positionFeatures = [
    new FeedElement('trip', 'TripDescriptor', 'Yes', undefined, [
        new FeedElement('trip_id', undefined, 'Yes'),
        new FeedElement('route_id', undefined, 'Yes'),
        new FeedElement(
            'direction_id',
            undefined,
            'Yes',
            'If available in the static feed.'
        ),
        new FeedElement('start_time', undefined, 'Yes'),
        new FeedElement('start_date', undefined, 'Yes'),
        new FeedElement(
            'schedule_relationship',
            'ScheduleRelationship',
            'Yes',
            'Supported values are:\nSCHEDULED'
        )
    ]),
    new FeedElement('vehicle', 'VehicleDescriptor', 'Yes', undefined, [
        new FeedElement('id', undefined, 'Yes'),
        new FeedElement(
            'label',
            undefined,
            'Yes',
            'trip_headsign if available in the static feed.'
        ),
        new FeedElement(
            'license_plate',
            undefined,
            'Yes',
            'Availability not guaranteed.'
        )
    ]),
    new FeedElement('position', 'Position', 'Yes', undefined, [
        new FeedElement('latitude', undefined, 'Yes'),
        new FeedElement('longitude', undefined, 'Yes'),
        new FeedElement('bearing', undefined, 'Yes'),
        new FeedElement('odometer', undefined, 'No'),
        new FeedElement('speed', undefined, 'Yes')
    ]),
    new FeedElement('current_stop_sequence', undefined, 'Yes'),
    new FeedElement('stop_id', undefined, 'Yes'),
    new FeedElement('current_status', undefined, 'Yes'),
    new FeedElement('timestamp', undefined, 'Yes'),
    new FeedElement(
        'congestion_level',
        'CongestionLevel',
        '“Yes“',
        'Supported value is:\nUNKNOWN_CONGESTION_LEVEL'
    ),
    new FeedElement('occupancy_status', 'OccupancyStatus', 'No')
]

const alertFeatures = [
    new FeedElement('active_period', 'TimeRange', 'Yes', undefined, [
        new FeedElement('start', undefined, 'Yes'),
        new FeedElement('end', undefined, 'Yes')
    ]),
    new FeedElement('informed_entity', 'EntitySelector', 'Yes', undefined, [
        new FeedElement('agency_id', undefined, 'Yes'),
        new FeedElement('route_id', undefined, 'Yes'),
        new FeedElement('route_type', undefined, 'Yes'),
        new FeedElement('trip', undefined, 'No'),
        new FeedElement('stop_id', undefined, 'Yes')
    ]),
    new FeedElement('cause', 'Cause', 'Yes'),
    new FeedElement('effect', 'Effect', 'Yes'),
    new FeedElement('url', 'TranslatedString', 'Yes', 'Configurable'),
    new FeedElement('header_text', 'TranslatedString', 'Yes'),
    new FeedElement('description_text', 'TranslatedString', 'Yes'),
    new FeedElement(
        'SeverityLevel',
        undefined,
        'Yes',
        'Supported values: INFO, WARNING, SEVERE'
    )
]

const SiriIntroduction = () => (
    <div>
        <h2 id={'siri-introduction'}>Introduction</h2>
        <h3>
            NOTE: SIRI is still in development and may have bugs or errors.
            Please report them to{' '}
            <a href={'mailto:antti@vertics.co'}>antti@vertics.co</a>
        </h3>
        <p>
            Currently known issues:
            <br />– Stop Monitoring may returns incorrect data
        </p>
        <p>
            This document describes the real-time SIRI service interfaces and is
            intended for developers aiming to develop solutions and services
            that use this real-time public transport data. The SIRI real-time
            service follows the SIRI standard version 1.3.
        </p>
        <p>
            The SIRI real-time services are implemented as request/response
            model web services. This means that each request must contain
            information which service/resource is mean to be consumed and what
            are the characteristics of the data that the consumer is expecting.
        </p>
        <p>
            The consumers of this web service must send HTTP POST requests to
            the web service. The content of these requests must be SIRI XML
            document containing one of the following service request elements:
            StopMonitoringRequest, VehicleMonitoringRequest or
            GeneralMessageRequest.
        </p>
        <p>
            In order to do periodical updates on the data, the applications
            consuming real-time SIRI services must make repeated calls to the
            web service.
        </p>
        <p>All requests are made to this endpoint:</p>
        <code className="doc-endpoint">
            POST https://data.waltti.fi/{cityParam}/api/sirirealtime/v1.3/ws
        </code>
        <h3>Usage limits</h3>
        <p>
            The request intervals (time between two sequential calls) varies for
            different request types based on real-time characteristics of the
            requested data. The following table illustrates the request
            intervals for SIRI services:
        </p>
        <FeatureTable
            features={siriIntroductionTable}
            headers={[
                'Siri Service',
                'Request interval (seconds)',
                'Background'
            ]}
        />
    </div>
)

const siriIntroductionTable = [
    new FeedElement(
        'VehicleMonitoring',
        undefined,
        '1',
        'Vehicles are positioned once every second'
    ),
    new FeedElement(
        'StopMonitoring',
        undefined,
        '30',
        'Stop calls are estimated with accuracy of one minute'
    ),
    new FeedElement(
        'GeneralMessaging',
        undefined,
        '60',
        'Messages are meant for broadcasting information about events that are not real-time by nature'
    )
]

const SiriVehicleMonitoring = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'siri-vehiclemonitoring'}>SIRI - Vehicle Monitoring</h2>
        <p>
            SIRI Vehicle Monitoring service (SIRI VM) is a SIRI service that is
            focused on transferring real- time information on the movement of
            vehicles.
        </p>
        <p>
            Vehicle data can be filtered by adding one the following elements to
            the service request:
        </p>
        <CustomTable
            headers={['Element', 'Description']}
            rows={[
                [
                    'VehicleRef',
                    'Reference to a specific vehicle about which data is requested'
                ],
                [
                    'LineRef',
                    'Filter the results to include only vehicles for the given line'
                ],
                [
                    'VehicleMonitoringRef',
                    'The pre-arranged identifier about which data is requested. Currently only identifier VEHICLES_ALL is supported'
                ]
            ]}
        />
        <h3>Request to get data for all vehicles:</h3>
        <CodeXML>
            {
                '<?xml version="1.0" encoding="UTF-8"?>\n<Siri xmlns="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3" xsi:schemaLocation="http://www.kizoom.com/standards/siri/schema/1.3/siri.xsd">\n\t<ServiceRequest>\n\t\t<VehicleMonitoringRequest version="1.3">\n\t\t\t<VehicleMonitoringRef>VEHICLES_ALL</VehicleMonitoringRef>\n\t\t</VehicleMonitoringRequest>\n\t</ServiceRequest>\n</Siri>'
            }
        </CodeXML>
        <h3>Request to get data for vehicles operating on line 30:</h3>
        <CodeXML>
            {
                '<?xml version="1.0" encoding="UTF-8"?>\n<Siri xmlns="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3" xsi:schemaLocation="http://www.kizoom.com/standards/siri/schema/1.3/siri.xsd">\n\t<ServiceRequest>\n\t\t<VehicleMonitoringRequest version="1.3">\n\t\t\t<LineRef>30</LineRef>\n\t\t</VehicleMonitoringRequest>\n\t</ServiceRequest>\n</Siri>'
            }
        </CodeXML>
    </div>
)

const SiriStopMonitoring = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'siri-stopmonitoring'}>Siri - Stop Monitoring</h2>
        <p>
            SIRI Stop Monitoring service (SIRI SM) is a SIRI service that is
            focused on transferring stop visit prognosis from stop point of
            view.
        </p>
        <p>
            Stop data can be filtered by adding the following element to service
            request:
        </p>
        <CustomTable
            headers={['Element', 'Description']}
            rows={[
                [
                    'MonitoringRef',
                    'Filter the results to include only stop calls for the given stop codes. NOTE! Maximum amount of stops per request is 100 (StopMonitoringRequest elements)'
                ]
            ]}
        />
        <h3>
            Request to get data for stops 0015 (Keskustori D) and 0504
            (Rautatieasema):
        </h3>
        <CodeXML>
            {
                '<?xml version="1.0" encoding="UTF-8"?>\n<Siri xmlns="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3" xsi:schemaLocation="http://www.kizoom.com/standards/siri/schema/1.3/siri.xsd">\n\t<ServiceRequest>\n\t\t<StopMonitoringRequest version="1.3">\n\t\t\t<PreviewInterval>PT30M00S</PreviewInterval>\n\t\t\t<MonitoringRef>0015</MonitoringRef>\n\t\t</StopMonitoringRequest>\n\t\t<StopMonitoringRequest version="1.3">\n\t\t\t<PreviewInterval>PT30M00S</PreviewInterval>\n\t\t\t<MonitoringRef>0504</MonitoringRef>\n\t\t</StopMonitoringRequest>\n\t</ServiceRequest>\n</Siri>'
            }
        </CodeXML>
    </div>
)

const SiriGeneralMessage = () => (
    <div className="gtfs-rt-feed-element">
        <h2 id={'siri-generalmessage'}>Siri - General Message</h2>
        <p>
            SIRI General Messaging service (SIRI GM) is a SIRI service that
            provides messaging information from traffic controller to external
            systems and passenger displays.
        </p>
        <p>Messaging data can be filtered by following channel elements:</p>
        <CustomTable
            headers={['Element', 'Description']}
            rows={[
                [
                    'InfoChannelRef',
                    'Filter the results to include only message targeted to given information channels. Possible values are errors, warnings and messages'
                ]
            ]}
        />
        <h3>Request to get all errors and warnings:</h3>
        <CodeXML>
            {
                '<?xml version="1.0" encoding="UTF-8"?>\n<Siri xmlns="http://www.siri.org.uk/siri" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.3" xsi:schemaLocation="http://www.kizoom.com/standards/siri/schema/1.3/siri.xsd">\n\t<ServiceRequest>\n\t\t<GeneralMessageRequest version="1.3">\n\t\t\t<InfoChannelRef>errors</InfoChannelRef>\n\t\t\t<InfoChannelRef>warnings</InfoChannelRef>\n\t\t</GeneralMessageRequest>\n\t</ServiceRequest>\n</Siri>'
            }
        </CodeXML>
    </div>
)

const Packages = () => (
    <div>
        <h2 id={'gtfs-static-packages'}>Static GTFS packages</h2>
        <p>
            This API also offers static GTFS packages in zip format. They are
            refreshed every night at 01:00 (EET). The packages are available
            under{' '}
            <a href={'https://creativecommons.org/licenses/by/4.0/'}>CC4.0BY</a>{' '}
            license
        </p>
        <p>
            Zipped package can be accessed here (replace XXX with the desired
            value):
        </p>
        <code className="doc-code">
            GET https://tvv.fra1.digitaloceanspaces.com/XXX.zip
        </code>
        <p>Currently the API supports following values:</p>
        <AuthorityTable />
    </div>
)

const authorities = [
    { value: '203', name: 'Hämeenlinna' },
    { value: '207', name: 'Joensuu' },
    { value: '209', name: 'Jyväskylä' },
    { value: '211', name: 'Kajaani' },
    { value: '217', name: 'Kotka' },
    { value: '219', name: 'Kouvola' },
    { value: '221', name: 'Kuopio' },
    { value: '223', name: 'Lahti' },
    { value: '225', name: 'Lappeenranta' },
    { value: '227', name: 'Mikkeli' },
    { value: '229', name: 'Oulu' },
    { value: '237', name: 'Rovaniemi' },
    { value: '239', name: 'Salo' },
    { value: '249', name: 'Vaasa' },
    { value: '113', name: 'Pohjois-Pohjanmaan ELY-keskus' },
    { value: '181', name: 'Pohjois-Savon ELY-keskus, Iisalmi' },
    { value: '182', name: 'Pohjois-Savon ELY-keskus, Kuopio' },
    { value: '183', name: 'Pohjois-Savon ELY-keskus, Joensuu' },
    { value: '184', name: 'Pohjois-Savon ELY-keskus, Mikkeli' }
]

const AuthorityTable = () => (
    <table className="doc-cityTable">
        <thead>
            <tr>
                <th>Authority ID</th>
                <th>Name</th>
            </tr>
        </thead>
        <tbody>
            {authorities.map(city => (
                <tr key={`city_table_${city.value}`}>
                    <td>{city.value}</td>
                    <td>{city.name}</td>
                </tr>
            ))}
        </tbody>
    </table>
)
