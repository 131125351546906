/**
 * React event component
 * Catches various events and invokes any callbacks
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Utility component to catch events
export default class ReactEvent extends Component {
    componentDidMount() {
        if (this.props.onMount) this.props.onMount()
    }

    componentDidUpdate() {
        if (this.props.onUpdate) this.props.onUpdate()
    }

    componentWillUnmount() {
        if (this.props.onUnmount) this.props.onUnmount()
    }

    render() {
        if (this.props.onRender) this.props.onRender()
        return <div />
    }
}

Event.propTypes = {
    onRender: PropTypes.func,
    onMount: PropTypes.func,
    onUpdate: PropTypes.func,
    onUnmount: PropTypes.func
}
