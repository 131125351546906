/**
 * Login action creator
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { auth } from './helpers'
import { GET_LOGIN_URL, LOGIN, LOG_OUT, VERIFY_TOKEN } from './types'

/**
 * Dispatched when user clicks 'Login with Google' option
 *
 * @return {object} The url used for authenticating the user
 */

export function getLoginUrl() {
    return {
        type: GET_LOGIN_URL,
        payload: {
            request: {
                method: 'GET',
                url: `/oauth2?source=${process.env.PLATFORM}`
            }
        }
    }
}

/**
 * Dispatched when user submits login form
 *
 * @param {object} code The code received from OAuth2 login
 *
 * @return {object} The created login action
 */

export const login = code => {
    return {
        type: LOGIN,
        payload: {
            request: {
                method: 'POST',
                url: '/oauth2',
                data: Object.assign({}, code, { source: process.env.PLATFORM })
            }
        }
    }
}

/**
 * Dispatched when user presses 'Log out'
 *
 * @param {object} bearer The bearer token
 */

export const logout = () => {
    return {
        type: LOG_OUT,
        payload: {
            request: {
                method: 'DELETE',
                url: '/bearers',
                headers: {
                    ...auth()
                }
            }
        }
    }
}

/**
 * Dispatched when AuthChecker changes status from false to true
 *
 * @param {object} bearer The bearer token
 *
 * @return {boolean} Is token valid?
 */

export const verifyToken = () => {
    return {
        type: VERIFY_TOKEN,
        payload: {
            request: {
                method: 'GET',
                url: '/bearers',
                headers: {
                    ...auth()
                }
            }
        }
    }
}
