import React, { Component } from 'react'

export default class LoadingCircle extends Component {
    static defaultProps = {
        size: 40,
        width: 3,
        color: '#001f4c',
        angle: 90 // Keep around 90
    }

    getPosFromAngle(deg, diameter = 1.0) {
        const pos = {}
        const rad = (deg * Math.PI) / 180
        pos.x = ((1 + Math.sin(rad)) / 2) * diameter
        pos.y = ((1 - Math.cos(rad)) / 2) * diameter
        return pos
    }

    render() {
        const { classes, size, width, color, angle } = this.props
        const cx1 = size / 2
        const cy1 = width
        const r = Math.floor(width / 2)
        const pos = this.getPosFromAngle(angle, size - width * 2)
        const cx2 = pos.x + width
        const cy2 = pos.y + width
        return (
            <div className={`loading-circle-container ${classes || ''}`}>
                <div className="loading-circle" style={{ width: `${size}px` }}>
                    <svg width="100%" height="100%">
                        <circle cx={cx1} cy={cy1} stroke={color} r={r} />
                        <path
                            d={`M ${cx1} ${cy1} A ${size / 2} ${size /
                                2}, 0, 0 1, ${cx2} ${cy2}`}
                            stroke={color}
                            strokeWidth={width}
                            fill="none"
                        />
                        <circle cx={cx2} cy={cy2} stroke={color} r={r} />
                    </svg>
                </div>
            </div>
        )
    }
}
