/**
 * Main container
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// actions
import { clearErrorMessage } from 'actions'
import { verifyToken } from 'actions/login'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import { getBearer, getVerifying } from 'reducers'

import AnalyticsPage from './AnalyticsPage'
import AuthChecker from './Authentication/AuthChecker'
import AuthRoute from './Authentication/AuthRoute'
// components
import ProtectedRoute from './Authentication/ProtectedRoute'
import CredentialsPage from './CredentialsPage'
import DocsPage from './DocsPage'
import GettingStartedPage from './GettingStartedPage'
import Nav from './Header/Nav'
import HomePage from './HomePage'
import LoadingCircle from './LoadingCircle'
import LoginPage from './LoginPage'
import OAuth2 from './OAuth2'
import ReactEvent from './ReactEvent'
import ErrorMessage from './UI/ErrorMessage'

class App extends Component {
    componentDidMount() {
        // clear all error messages
        this.props.clearErrorMessage()
    }

    render() {
        const { verifying } = this.props

        return (
            <div>
                <Helmet
                    titleTemplate={`%s | ${
                        process.env.PLATFORM === 'nysse' ? 'Nysse' : 'Waltti'
                    } GTFS Realtime`}
                    defaultTitle={`${
                        process.env.PLATFORM === 'nysse' ? 'Nysse' : 'Waltti'
                    } GTFS Realtime`}
                />
                <AuthChecker>
                    {({ isAuthenticated }) =>
                        isAuthenticated ? (
                            <ReactEvent onMount={this.props.verifyToken} />
                        ) : null
                    }
                </AuthChecker>
                <div className="app-container">
                    <Nav />
                    {verifying || verifying === undefined ? null : (
                        <Switch>
                            <Route exact path="/" component={HomePage} />
                            <Route
                                exact
                                path="/getting-started"
                                component={GettingStartedPage}
                            />
                            <Route exact path="/docs" component={DocsPage} />
                            <ProtectedRoute
                                exact
                                path="/analytics"
                                component={AnalyticsPage}
                                analytics
                            />
                            {/* <ProtectedRoute
                                exact
                                path="/account"
                                component={CredentialsPage}
                            /> */}
                            <Route exact path="/login" component={LoginPage} />
                            <Route exact path="/oauth2" component={OAuth2} />
                            <Redirect to="/" />
                        </Switch>
                    )}
                </div>
                {/*
					Render error message right away
					Will return null if there is no such error
				*/}
                <ErrorMessage />
            </div>
        )
    }
}

export default connect(
    state => ({
        verifying: getVerifying(state),
        bearer: getBearer(state)
    }),
    dispatch => ({
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        verifyToken: () => dispatch(verifyToken())
    })
)(App)
