/**
 * Helpers for action creators
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { store } from '../store'
import { getBearer } from 'reducers'

// import axios from 'axios';

// // error handling helper
// const errorHandler = (error) => {
// 	// do something with the error
// 	// probably a switch case if we are not sure about data type of error
// 	// log it first
// 	console.log(error);
// 	return error;
// }

// // get bearer helper
// const getBearer = async (user) => {
// 	const { email, password } = user;
// 	try {
// 		const data = await axios.post(`${AUTH_API}/bearers`).data;
// 		return data;
// 	} catch(error) {
// 		errorHandler(error)
// 	}
// }

// // login helper
// const logUserIn = async (bearer) => {
// 	try {
// 		return await axios.get(`${AUTH_API}/bear`);
// 	} catch(error) {
// 		errorHandler(error);
// 	}
// }

// // export all helper methods
// export {
// 	getBearer,
// 	errorHandler,
// }

export const clearErrorMessage = () => {
    return {
        type: 'CLEAR_ERROR_MESSAGE'
    }
}

export const auth = () => {
    return {
        authorization: `Bearer ${getBearer(store.getState())}`
    }
}
