/**
 * Waltti OIDC login component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUrl } from 'reducers'
import { isAuthenticated } from 'reducers'
import { logout, getLoginUrl } from 'actions'

class WalttiLogin extends Component {
    navigate = url => {
        window.location = url
    }

    handleClick = () => {
        if (this.props.loggedIn) this.props.logout()
        this.props.getLoginUrl()
    }

    render() {
        const { url } = this.props
        if (url) this.navigate(url)
        return (
            <div className="waltti-login-container">
                <button type="outlined" onClick={this.handleClick}>
                    <span className="waltti-login-text">
                        Login / Sign up with Waltti ID
                    </span>
                </button>
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({
        url: getUrl(state),
        loggedIn: isAuthenticated(state)
    }),
    (dispatch, ownProps) => ({
        getLoginUrl: () => dispatch(getLoginUrl()),
        logout: () => dispatch(logout())
    })
)(WalttiLogin)
