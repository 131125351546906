/**
 * FeatureTable component
 *
 * @author Antti Vuorenmaa <antti@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow } from './Table'

export default class CustomTable extends Component {
    renderRow = (values, index) => {
        return (
            <TableRow key={`row_${index}`}>
                {values.map(text => (
                    <TableCell key={text}>{text}</TableCell>
                ))}
            </TableRow>
        )
    }

    render() {
        const { rows, headers } = this.props

        return (
            <div className={'custom-table-container'}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headers.map(text => (
                                <TableCell key={text}>{text}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows.map(this.renderRow)}</TableBody>
                </Table>
            </div>
        )
    }
}
