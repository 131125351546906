/**
 * GettingStartedPage component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import WalttiSignup from '../assets/images/1-waltti-signup.jpg'
import WalttiRegisterFilled from '../assets/images/2-waltti-register-filled.jpg'
import WalttiRegisterVerify from '../assets/images/3-waltti-register-verify2.jpg'
import WalttiRegisterDone from '../assets/images/4-waltti-register-done.jpg'
import WalttiDashboard from '../assets/images/5-waltti-dashboard.jpg'
import WalttiManagement from '../assets/images/6-waltti-management.jpg'
import WalttiManagementNew from '../assets/images/7-waltti-management-new.jpg'
import WalttiManagementCreds from '../assets/images/8-waltti-management-creds.jpg'
import { HOST_NAME } from '../constants'
import Code from './Code'
import TableOfContents from './TableOfContents'

const tableOfContents = [
    {
        text: 'Getting started',
        value: '#getting-started'
    },
    {
        text: 'Waltti ID account',
        value: '#waltti-id'
    },
    {
        text: 'API Tokens',
        value: '#api-tokens'
    },
    {
        text: 'Authenticating requests',
        value: '#authenticating'
    }
]

export default class GettingStartedPage extends Component {
    render() {
        return (
            <div className={'app-page-container'}>
                <Helmet>
                    <title>Getting Started</title>
                    <meta
                        name={'description'}
                        content={`Getting started | ${
                            process.env.PLATFORM === 'nysse'
                                ? 'Nysse'
                                : 'Waltti'
                        } API`}
                    />
                </Helmet>
                <TableOfContents data={tableOfContents} />
                <div className={'app-centered-content'}>
                    <div className={'app-content-width-controller'}>
                        <GettingStarted />
                        <Account />
                        <ApiTokens />
                        <AuthenticatingRequests />
                    </div>
                </div>
            </div>
        )
    }
}

const GettingStarted = () => (
    <>
        <h1 id={'getting-started'}>Getting started</h1>
        <p>
            This will get you started on using the{' '}
            {process.env.PLATFORM === 'nysse' ? 'Nysse' : 'Waltti'} GTFS RT
            public API.
        </p>
        <p>
            API calls are made to <a href={HOST_NAME}>{HOST_NAME}</a>
        </p>
    </>
)

const Account = () => (
    <>
        <h2 id={'waltti-id'}>Waltti ID account</h2>
        <p>
            First you will need a Waltti ID account. You can{' '}
            <Link to={'/login'}>sign up</Link> to create one.
        </p>

        <h3>Create a Waltti ID account.</h3>
        <div className={'tutorial-privacy-policy-container'}>
            <a
                href={
                    'https://kauppa.waltti.fi/media/authority/154/files/Privacy_Policy_LMJ.pdf'
                }
            >
                Privacy policy
            </a>
        </div>
        <div className={'tutorial-image-container'}>
            <img src={WalttiSignup} alt={'Waltti signup'} />
        </div>
        <h3>Fill in the required fields:</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiRegisterFilled} alt={'Register form'} />
        </div>
        <h3>Verify the email address:</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiRegisterVerify} alt={'Verify email'} />
        </div>
        <h3>After which your account is ready.</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiRegisterDone} alt={'Register done'} />
        </div>
        <p>
            If you have any problems, please contact{' '}
            <a href={'mailto:info@lmj.fi'}>info@lmj.fi</a>
        </p>
    </>
)

const ApiTokens = () => (
    <>
        <h2 id={'api-tokens'}>Creating your own API tokens</h2>
        <p>API tokens are managed through the Waltti ID service.</p>
        <p>
            Here is a link to the{' '}
            <a href={'https://id.waltti.fi/ui#!dashboard'}>
                Waltti ID dashboard
            </a>
            . Login with the same user as with this site.
        </p>
        <p>
            Under the "Management API" (Hallinta-API) menu, press "Register new"
            (Rekisteröi uusi). Give the API client a name, and optionally
            configure the IP address rules. After that, hit save and store the
            Client ID and Client secret somewhere secure.{' '}
            <b>
                The client secret is never shown again, so if you lose it, you
                have to generate a new one.
            </b>
        </p>
        <h3>Select the Management API (Hallinta-API) panel.</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiDashboard} alt={'Waltti dashboard'} />
        </div>
        <h3>Register a new client.</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiManagement} alt={'New client'} />
        </div>
        <h3>Fill in the client name and save.</h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiManagementNew} alt={'Client info'} />
        </div>
        <h3>
            Store the Client ID and Client secret somewhere secure.{' '}
            <b>
                The client secret is never shown again, so if you lose it, you
                have to generate it again.
            </b>
        </h3>
        <div className={'tutorial-image-container'}>
            <img src={WalttiManagementCreds} alt={'Client credentials'} />
        </div>
    </>
)

const AuthenticatingRequests = () => (
    <>
        <h2 id={'authenticating'}>Authenticating your requests</h2>
        <p>
            API requests are authenticated with{' '}
            <a href={'https://tools.ietf.org/html/rfc2617#section-2'}>
                Basic authentication
            </a>{' '}
            scheme. Supply your client ID and client secret to the
            'Authorization' header.
        </p>
        <p>Example of a request with curl would look like:</p>
        <Code>
            curl -X GET "{HOST_NAME}
            /tampere/api/gtfsrealtime/v1.0/feed/servicealert" -H "Authorization:
            Basic XXXXXXXXXX"
        </Code>
        <p>
            Where you replace "XXXXXXXXXX" with your credentials encoded in
            base64 as such:
        </p>
        <p>
            base64("<b>{'<client ID>'}</b>:<b>{'<client secret>'}</b>")
        </p>
        <p>
            So a client ID of 'client_ID' and client secret of 'client_secret'
            would result in base64("client_ID:client_secret") =>
            Y2xpZW50IElEOmNsaWVudCBzZWNyZXQ=
        </p>
        <p>And the request would look like:</p>
        <Code>
            curl -X GET "{HOST_NAME}/
            {process.env.PLATFORM === 'nysse' ? 'tampere' : ':city'}
            /api/gtfsrealtime/v1.0/feed/servicealert" -H "Authorization: Basic
            Y2xpZW50IElEOmNsaWVudCBzZWNyZXQ="
        </Code>

        {process.env.PLATFORM === 'waltti' && (
            <p>
                More information about the city parameter{' '}
                <a href={'/docs#supportedCities'}>in the docs</a>.
            </p>
        )}
    </>
)
