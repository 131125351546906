/**
 * Panel decorator component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'

export default class Panel extends Component {
    render() {
        const { classes, children, component } = this.props
        const Comp = component || 'div'
        return (
            <Comp className={`panel-component-container ${classes || ''}`}>
                {children}
            </Comp>
        )
    }
}
