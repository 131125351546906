/**
 * Main nav component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUser, history } from 'reducers'

import { logout } from '../../actions'
import NysseLogo from '../../assets/icons/nysse-logo.svg'
import WalttiLogo from '../../assets/icons/waltti-logo.svg'
import AuthChecker from '../Authentication/AuthChecker'

const Logo = process.env.PLATFORM === 'nysse' ? NysseLogo : WalttiLogo

class Navigation {
    constructor(route, name, active = true) {
        this.route = route
        this.name = name
        this.active = active
    }
}

class NavBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isNavOpen: false,
            windowWidth: window.innerWidth
        }
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () =>
        this.setState({ windowWidth: window.innerWidth })

    mobileNavClick = route => {
        this.setState({ isNavOpen: false })
        return history.push(route)
    }

    renderNavigation = item => {
        return item.active ? (
            <div
                key={item.name}
                className={`main-nav-navigation ${
                    this.props.route === item.route ? 'selected' : ''
                }`}
            >
                <Link to={item.route}>
                    <div className={'main-nav-link-content'}>
                        <span className={'main-nav-link-text'}>
                            {item.name}
                        </span>
                    </div>
                </Link>
            </div>
        ) : null
    }

    renderMobileNavigation = (item, isAuthenticated) => {
        if (isAuthenticated && item.route === '/login') return null
        return (
            <div
                className={`item ${item.active ? 'active' : ''}`}
                onClick={() => this.mobileNavClick(item.route)}
                key={item.name}
            >
                {item.name}
            </div>
        )
    }

    toggleNav = () => this.setState({ isNavOpen: !this.state.isNavOpen })

    render() {
        const { user } = this.props
        const { isNavOpen, windowWidth } = this.state

        return (
            <AuthChecker>
                {({ isAuthenticated, showAnalytics }) => {
                    const navigations = [
                        new Navigation('/', 'Home'),
                        new Navigation('/getting-started', 'Getting Started'),
                        new Navigation('/docs', 'Docs'),
                        new Navigation('/analytics', 'Analytics', showAnalytics)
                        // new Navigation('/test', 'Test', false)
                    ]
                    const navigationsMobile = [
                        new Navigation('/', 'Home'),
                        new Navigation('/getting-started', 'Getting Started'),
                        new Navigation('/docs', 'Docs'),
                        new Navigation('/login', 'Log in / sign up'),
                        new Navigation('/analytics', 'Analytics', showAnalytics)
                        // new Navigation('/test', 'Test', false)
                    ]
                    // Render mobile
                    if (windowWidth <= 650) {
                        return (
                            <>
                                <div className={'mobile-nav-spacer'} />
                                <div className={'mobile-nav'}>
                                    <div className={'logo'}>
                                        <Link to={'/'}>
                                            <img src={Logo} />
                                        </Link>
                                    </div>
                                    <div
                                        className={'menu'}
                                        onClick={this.toggleNav}
                                    >
                                        <div />
                                        <div />
                                        <div />
                                    </div>
                                </div>
                                <div
                                    className={`mobile-nav-collapsible ${
                                        isNavOpen ? 'open' : ''
                                    }`}
                                >
                                    {navigationsMobile.map(item =>
                                        this.renderMobileNavigation(
                                            item,
                                            isAuthenticated
                                        )
                                    )}
                                    {isAuthenticated && (
                                        <div
                                            className={'item logout'}
                                            onClick={() => this.props.logout()}
                                            key={'logout'}
                                        >
                                            <span>
                                                {`${user.firstName ||
                                                    ''} ${user.lastName ||
                                                    ''}`}{' '}
                                                / {user.email}
                                            </span>
                                            <span>Log out</span>
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    }
                    return (
                        <div className="main-nav-container">
                            <nav>
                                <div className="text-container title-container">
                                    <Link to="/">
                                        <img src={Logo} />
                                    </Link>
                                </div>
                                <div className="filler" />
                                {this.props.route !== '/oauth2' && (
                                    <Fragment>
                                        {navigations.map(this.renderNavigation)}
                                        <div className="filler" />
                                        {isAuthenticated && (
                                            <Fragment>
                                                <div className="text-container">
                                                    <div className="name-container">
                                                        {`${user.firstName ||
                                                            ''} ${user.lastName ||
                                                            ''}`}
                                                    </div>
                                                    <div className="email-container">
                                                        {user.email}
                                                    </div>
                                                </div>
                                                <button
                                                    type="text"
                                                    className="nav-button main-nav-sign-button"
                                                    onClick={this.props.logout}
                                                >
                                                    Log out
                                                </button>
                                            </Fragment>
                                        )}
                                        {!isAuthenticated && (
                                            <Fragment>
                                                <div className="main-nav-logout-container nav-button main-nav-sign-button">
                                                    <Link to="/login">
                                                        <span>
                                                            Log in / sign up
                                                        </span>
                                                    </Link>
                                                </div>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )}
                            </nav>
                        </div>
                    )
                }}
            </AuthChecker>
        )
    }
}

const parseBaseRoute = path => {
    if (!path) return undefined
    if (path[0] !== '/') return undefined
    if (path === '/') return path
    const subRouteIndex = path.indexOf('/', 1)
    return path.slice(0, subRouteIndex === -1 ? path.length : subRouteIndex - 1)
}

export default connect(
    (state, ownProps) => ({
        user: getUser(state),
        route: parseBaseRoute(state.router.location.pathname)
    }),
    (dispatch, ownProps) => ({
        logout: () => dispatch(logout())
    })
)(NavBar)
