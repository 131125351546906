/**
 * @author Antti Vuorenmaa <antti@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    CartesianGrid,
    LineChart as Chart,
    Legend,
    Line,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts'

import { getAnalyticsData } from '../../actions/analytics'
import { GET_ANALYTICS_DATA_SUCCESS } from '../../actions/types'
import TabSelection from './Components/tabSelection'
import { generateColors } from './utils'

class LineChart extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }

        this.colors = []
    }

    componentDidMount = () => {
        this.getData()
    }

    componentWillReceiveProps = nextProps => {
        if (!_.isEqual(nextProps.tabSelection, this.props.tabSelection))
            this.getData()
    }

    getData = async () => {
        const params = qs.stringify(this.props.returnParams())

        const result = await this.props.getData(params)
        if (result.type === GET_ANALYTICS_DATA_SUCCESS) {
            this.colors = generateColors(
                Object.keys((result.payload.data || [{}])[0]).filter(
                    val => val !== 'date'
                ).length
            )
            this.setState({ data: result.payload.data })
        } else {
            let alertString = 'ERROR'
            if (result.error.message)
                alertString += `\n\n${result.error.message}`
            if (result.error.response && result.error.response.data)
                alertString += `\n${result.error.response.data}`
            alert(alertString)
        }
    }

    getIndicatorName = value => {
        const parsed = JSON.parse(value)
        // console.log(parsed)
        if (_.isEmpty(parsed)) return 'Requests'
        return Object.keys(parsed)
            .map(key => parsed[key])
            .join(', ')
    }

    getFormattedDate = date => {
        const { accuracy } = this.props
        if (accuracy === 'hour')
            return moment(date).format(`dddd, MMMM Do HH[:00-]HH[:59]`)
        if (accuracy === 'day') return moment(date).format('dddd, MMMM Do')
        if (accuracy === 'week') return moment(date).format('[Week] W, YYYY')
        if (accuracy === 'month') return moment(date).format('MMMM, YYYY')
        return moment(date).calendar()
    }

    getFormattedDateXAxis = date => {
        const { accuracy } = this.props
        if (accuracy === 'hour' || accuracy === 'day')
            return moment(date).format('DD/MM')
        if (accuracy === 'week') return moment(date).format('W/YYYY')
        if (accuracy === 'month') return moment(date).format('MM/YYYY')
        return moment(date).calendar()
    }

    getXAxisTicks = data => {
        const dates = data.map(obj => obj.date)
        const { accuracy } = this.props
        if (accuracy === 'hour')
            return dates.filter(date => moment(date).get('hour') === 0)
        return dates
    }

    render() {
        const { title, infoText, tabSelection } = this.props
        const { data } = this.state

        return (
            <div className={'analytics-chart'}>
                <div className={'header-container'}>
                    <div className={'left'}>
                        <h2 onClick={() => this.getData()}>{title}</h2>
                        <p>{infoText}</p>
                    </div>
                    <div className={'right'}>
                        {/* <Button text={'Options'} /> */}
                    </div>
                </div>
                <TabSelection
                    values={tabSelection}
                    handleSelect={this.props.tabOnSelect}
                />

                <Chart
                    data={data}
                    width={Math.min(window.innerWidth - 2 * 56, 858)}
                    height={window.innerWidth >= 1000 ? 500 : 300}
                    margin={{ top: 20, right: 30, left: 15, bottom: 5 }}
                >
                    <CartesianGrid strokeDasharray={'3 3'} />
                    <XAxis
                        dataKey={'date'}
                        tickFormatter={date => this.getFormattedDateXAxis(date)}
                        minTickGap={20}
                        ticks={this.getXAxisTicks(data)}
                    />
                    <YAxis />
                    <Tooltip
                        formatter={(value, name) => [
                            value,
                            this.getIndicatorName(name)
                        ]}
                        labelFormatter={date => this.getFormattedDate(date)}
                    />
                    <Legend formatter={value => this.getIndicatorName(value)} />
                    {!_.isEmpty(data) &&
                        Object.keys(data[0]).map((key, index) => {
                            if (key === 'date') return null
                            return (
                                <Line
                                    type={'linear'}
                                    dataKey={key}
                                    stroke={this.colors[index]}
                                    key={`chart_${index}_line_${key}`}
                                />
                            )
                        })}
                </Chart>
            </div>
        )
    }
}

LineChart.propTypes = {
    title: PropTypes.string.isRequired,
    infoText: PropTypes.string.isRequired,
    tabSelection: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            selected: PropTypes.bool.isRequired,
            index: PropTypes.number.isRequired
        })
    ).isRequired,
    accuracy: PropTypes.string.isRequired,
    tabOnSelect: PropTypes.func.isRequired,
    returnParams: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    getData: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    getData: params => dispatch(getAnalyticsData(params))
})

export default connect(
    null,
    mapDispatchToProps
)(LineChart)
