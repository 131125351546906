/**
 * Login reducer
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { PERSIST_KEY } from 'constants'

import {
    GET_LOGIN_URL_FAIL,
    GET_LOGIN_URL_SUCCESS,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOG_OUT,
    VERIFY_TOKEN,
    VERIFY_TOKEN_FAIL,
    VERIFY_TOKEN_SUCCESS
} from 'actions/types'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const initialState = {
    bearer: undefined,
    user: undefined,
    loading: false,
    authUrl: undefined,
    authState: undefined,
    verifying: undefined
}

const persistConfig = {
    key: PERSIST_KEY + '-login',
    storage,
    blacklist: ['authUrl', 'authState', 'verifying']
}

function login(state = initialState, action) {
    switch (action.type) {
        case GET_LOGIN_URL_SUCCESS:
            return {
                ...state,
                authUrl: action.payload.data.url
            }
        case GET_LOGIN_URL_FAIL:
            return {
                ...state,
                authUrl: null
            }
        case LOGIN:
            return {
                ...state,
                authState: null,
                loading: true
            }
        case LOGIN_SUCCESS:
            return {
                ...state,
                authState: true,
                loading: false,
                bearer: action.payload.data.bearer,
                user: action.payload.data.user
            }
        case LOGIN_FAIL:
            return {
                ...state,
                authState: false,
                loading: false,
                bearer: null,
                user: null
            }
        case VERIFY_TOKEN:
            return {
                ...state,
                verifying: true
            }
        case VERIFY_TOKEN_SUCCESS:
            return {
                ...state,
                bearer: action.payload.data ? state.bearer : undefined,
                user: action.payload.data ? state.user : undefined,
                verifying: false
            }
        case VERIFY_TOKEN_FAIL:
            return {
                ...state,
                verifying: false
            }
        case LOG_OUT:
            return {
                ...initialState
            }
        default:
            return state
    }
}

export const getUrl = state =>
    state ? state.login.authUrl : initialState.authUrl

export const getAuthState = state =>
    state ? state.login.authState : initialState.authState

export const getUser = state => (state ? state.login.user : initialState.user)

export const getBearer = state =>
    state ? state.login.bearer : initialState.bearer

export const isAuthenticated = state =>
    Boolean(getUser(state)) && Boolean(getBearer(state))

export const showAnalytics = state => {
    try {
        return state.login.user.groups.includes('Analytics')
    } catch (error) {
        // console.error(error)
        return false
    }
}

export const getVerifying = state => {
    return state.login.bearer ? state.login.verifying : false
}

export default persistReducer(persistConfig, login)
