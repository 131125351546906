/**
 * Credentials page component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

class HomePage extends Component {
    render() {
        return (
            <div className="app-page-container">
                <Helmet>
                    <title>Home</title>
                    <meta
                        name="description"
                        content={`Home | ${
                            process.env.PLATFORM === 'nysse'
                                ? 'Nysse'
                                : 'Waltti'
                        } API`}
                    />
                </Helmet>
                <div className="home-page">
                    <div className="app-centered-content">
                        <div className="app-content-width-controller">
                            <h1>
                                {process.env.PLATFORM === 'nysse'
                                    ? 'Nysse'
                                    : 'Waltti'}{' '}
                                Public GTFS Realtime API
                            </h1>
                            <p>
                                Developer portal for the usage of{' '}
                                {process.env.PLATFORM === 'nysse'
                                    ? 'Nysse'
                                    : 'Waltti'}{' '}
                                Public GTFS RT API
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    (state, ownProps) => ({}),
    (dispatch, ownProps) => ({})
)(HomePage)
