/**
 * Login page component
 *
 * @author Hannes Heikkinen <hannes@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component, Fragment } from 'react'
import { Helmet } from 'react-helmet'

import WalttiLogin from './Authentication/WalttiLogin'
import Panel from './UI/Panel'

export default class LoginPage extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.props.history.replace('/')
        }
    }

    render() {
        // const { error } = this.props;
        return (
            <Fragment>
                <Helmet>
                    <title>Login</title>
                    <meta
                        name="description"
                        content={`Login | ${
                            process.env.PLATFORM === 'nysse'
                                ? 'Nysse'
                                : 'Waltti'
                        } API`}
                    />
                </Helmet>
                <div className="login-page-container">
                    <Panel classes="login-page-panel">
                        <div className="login-suggestion">
                            You can login or sign up with Waltti ID.
                        </div>
                        <WalttiLogin />
                    </Panel>
                </div>
            </Fragment>
        )
    }
}
